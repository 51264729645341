<template>
<div>
    <div v-if="skeletonLoading">
        <v-row>
            <v-col cols="12" md="12">
                <v-skeleton-loader
                min-height="600px"
                type="article, article, article, actions"
                >
                </v-skeleton-loader>
        </v-col>
        </v-row>
    </div>
    
    <div v-if="!skeletonLoading">
    
        <v-row>
            <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
                <v-subheader class="text-h5 black--text">
                    Datos financieros
                </v-subheader>
            </v-col>
        </v-row>

        <v-card outlined>
            <v-card-text>

                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <span class="card-label text-h6 font-weight-bolder text-dark">
                            Detalle del monto suscrito</span><br />
                        <span class="red--text mt-3 font-weight-bold text-subtitle-1 d-none">
                            
                        </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-divider class="mt-0 pt-3 pb-2"></v-divider>
                    </v-col>
                </v-row> 


                <v-form  
                    ref="formProyecciones"
                    v-on:submit.prevent="registrarMontoSuscrito"
                    v-model="validFormMontoSuscrito"
                    >

                    <v-row>

                        <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                            <v-autocomplete
                                v-model="datosMontoSuscrito.monedasId"
                                :items="monedas"
                                :loading="ddMonedasLoading"
                                dense
                                filled   
                                label="Moneda"
                                :item-text="item => item.nombreMoneda +  ' ('+ item.codigoMoneda + ')' "
                                item-value="id"
                                :rules="[
                                    selectRequired('moneda'),
                                ]"
                                :no-data-text="
                                    monedas != null
                                    ? 'Selecciona una moneda'
                                    : 'No se han encontrado monedas'
                                "
                                menu-props="auto"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="montoMonedaOriginalMonto"
                                :label="`Monto moneda original`"
                                :rules="[
                                    required('monto moneda original'),
                                    decimals10('monto moneda original')
                                ]"
                                maxlength="30"
                            ></v-text-field>
                        </v-col>


                        <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="tipoCambioMonto"
                                label="Tipo de cambio"
                                :rules="[
                                    required('tipo de cambio'),
                                    decimals10('tipo de cambio')
                                ]"
                                maxlength="30"
                            ></v-text-field>
                        </v-col>


                        <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                :disabled="true"
                                autocomplete="off"
                                class="required"
                                suffix="GTQ"
                                color="blue-grey lighten-2"
                                v-model="datosMontoSuscrito.montoTotalQuetzales"
                                label="Monto total quetzales"
                                maxlength="30"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="8" sm="12" class="pt-0 pb-0" v-if="tipo===2"> 
                            
                            <v-btn
                                color="light-blue-502"
                                class="white--text mb-2 float-right"
                                type="submit"
                                :elevation="0"
                                :disabled="!validFormMontoSuscrito"
                                :loading="btnRegistroMontoSuscrito"
                                
                            >
                                {{btnRegistroMontoSuscritoText}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>

            </v-card-text>

        </v-card>




        <v-card outlined class="mt-4">
            <v-card-text>

                <v-row>
                    <v-col cols="12" sm="12" md="12">
                        <span class="card-label text-h6 font-weight-bolder text-dark">
                            Proyección de desembolsos</span><br />
                        <span class="red--text mt-3 font-weight-bold text-subtitle-1 d-none">
                            
                        </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-divider class="mt-0 pt-3 pb-2"></v-divider>
                    </v-col>
                </v-row> 


                <v-row v-if="!montoSuscritoRegistrado">
                     <v-col cols="12" sm="12" md="12">
                        <v-alert
                            text
                            type="error"
                            >
                                Para poder agregar desembolsos, debe registrar el monto suscrito!
                        </v-alert>
                    </v-col>
                    
                </v-row>

                <v-form  v-if="montoSuscritoRegistrado && tipo===2"
                    ref="formProyecciones"
                    v-on:submit.prevent="registrarProyeccion"
                    v-model="validFormProyeccion"
                    >

                    <v-row>

                        <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                            <v-select
                                v-model="datosProyeccion.periodosId"
                                :items="periodos"
                                :loading="ddPeriodosLoading"
                                dense
                                filled  
                                label="Año/Periodo"
                                item-text="periodo"
                                item-value="id"
                                :rules="[
                                    selectRequired('periodo / año'),
                                ]"
                                :no-data-text="
                                    periodos.length > 0
                                    ? 'Selecciona un periodo'
                                    : 'No se han encontrado periodos'
                                "
                                menu-props="auto"
                            ></v-select>
                            
                        </v-col>

                        <v-col cols="12" md="4" sm="6" class="pt-0 pb-0">
                            <v-autocomplete
                                v-model="monedaProyeccionSeleccionada"
                                :items="monedas"
                                :loading="ddMonedasLoading"
                                dense
                                filled   
                                label="Moneda"
                                :item-text="item => item.nombreMoneda +' ('+ item.codigoMoneda + ')'"
                                item-value="id"
                                return-object
                                :rules="[
                                    selectRequired('moneda'),
                                ]"
                                :no-data-text="
                                    monedas != null
                                    ? 'Selecciona una moneda'
                                    : 'No se han encontrado monedas'
                                "
                                menu-props="auto"
                            ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="montoMonedaOriginalProyeccion"
                                :label="`Monto moneda original (${monedaProyeccionSeleccionada.codigoMoneda ? monedaProyeccionSeleccionada.codigoMoneda : 'Moneda no seleccionada' })`"
                                :rules="[
                                    required('monto moneda original'),
                                    decimals10('monto moneda original')
                                ]"
                                maxlength="30"
                            ></v-text-field>
                        </v-col>


                        <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                autocomplete="off"
                                class="required"
                                color="blue-grey lighten-2"
                                v-model="tipoCambioProyeccion"
                                label="Tipo de cambio"
                                :rules="[
                                    required('tipo de cambio'),
                                    decimals10('tipo de cambio')
                                ]"
                                maxlength="30"
                            ></v-text-field>
                        </v-col>


                        <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                            <v-text-field
                                dense
                                filled
                                :disabled="true"
                                autocomplete="off"
                                class="required"
                                suffix="GTQ"
                                color="blue-grey lighten-2"
                                v-model="datosProyeccion.montoTotalQuetzales"
                                label="Monto total quetzales"
                                maxlength="30"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                            
                            <v-btn
                                color="light-blue-502"
                                class="white--text mb-2 float-right"
                                type="submit"
                                :elevation="0"
                                :disabled="!validFormProyeccion"
                                :loading="btnRegistroProyeccion"
                                
                            >
                                Registrar proyección
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>


                <v-row class="mt-4" v-if="montoSuscritoRegistrado">
                    <v-col cols="12" md="12" sm="12">
                        <v-subheader class="text-subtitle-1 black--text">
                            Proyecciones registradas
                        </v-subheader>
                        <v-divider class="mt-0 pt-0"></v-divider>
                    </v-col>
                </v-row>

                <v-row v-if="montoSuscritoRegistrado">
                    
                    <!--inicio:: tabla datos financieros -->
                    <v-col cols="12" md="12" sm="12" class="pt-2">
                        <v-data-table outlined
                            dense
                            class="elevation-1"
                            :headers="headersProyecciones"
                            :items="proyecciones"
                            :loading="tableProyeccionesLoading"
                            hide-default-footer
                            >

                                <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.periodo }}</td>
                                    <td>{{ item.nombreMoneda }}</td>
                                    <td class="text-right">{{ `${item.codigoMoneda}  ${item.montoMonedaOriginal}` }}</td>
                                    <td class="text-right">{{ item.tipoCambio }}</td>
                                    <td class="text-right">{{ parseFloat(item.montoTotalQuetzales).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' }) }}</td>
                                    <!-- <td class="text-right"> Q {{ parseFloat(item.montoTotalQuetzales) }}</td> -->
                                    <td>
                                        <v-btn
                                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                            small
                                            depressed
                                            :disabled="btnEliminarProyeccionDisabled"
                                            color="blue-grey lighten-5"
                                            @click="eliminarProyeccion(item.id)"
                                            >
                                            <v-icon left>mdi-delete</v-icon> Eliminar
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>

                            <template v-slot:no-data>
                                <p class="text-h7">
                                <v-icon left>mdi-alert</v-icon> No existe información registrada de datos financieros
                                </p>
                            </template>

                        </v-data-table>

                    </v-col>
                    <!--fin:: tabla datos financieros -->
                    <!-- inicio: total de la secion -->

                    <v-col cols="12" md="4" sm="12" class="pt-0 pb-0"  v-if="proyecciones.length > 0">
                        <v-list-item two-line>
                            <v-list-item-content class="pb-0 pt-0">
                                <v-list-item-title class="text-h6">Total proyecciones registradas</v-list-item-title>
                                <v-list-item-subtitle class="text-h6">{{parseFloat(totalProyecciones).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}} / {{parseFloat(totalMontoSuscrito).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>

                    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                        <!--<v-subheader class="text-h5 black--text">
                            
                        </v-subheader>-->
                        <v-alert
                            text
                            :value="alertaTotalProyecciones"
                            type="error"
                            icon="mdi-alert"
                            >
                             El total de las proyecciones registradas ({{parseFloat(totalProyecciones).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}}) no coincide con el monto suscrito ({{parseFloat(totalMontoSuscrito).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}})
                        </v-alert>
                        <!--<v-divider class="mt-0 pt-1 pb-6"></v-divider>-->
                    </v-col>
                    
                    <!-- <v-col cols="12" md="4" sm="12" class="pt-0 pb-0"  v-if="proyecciones.length > 0">
                        <v-list-item two-line>
                            <v-list-item-content class="pb-0 pt-0">
                                <v-list-item-title class="text-h6">Suscrito Monto Nacional</v-list-item-title>
                                <v-list-item-subtitle class="text-h6">{{parseFloat(totalDatosFinancierosCP).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>

                    <v-col cols="12" md="4" sm="12" class="pt-0 pb-0"  v-if="proyecciones.length > 0">
                        <v-list-item two-line>
                            <v-list-item-content class="pb-0 pt-0">
                                <v-list-item-title class="text-h6">Monto total</v-list-item-title>
                                <v-list-item-subtitle class="text-h6">{{parseFloat(totalDatosFinancieros).toLocaleString("es-GT", { style: 'currency', currency: 'GTQ' })}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col> -->
                    <!--fin:: total de la seccion -->

                </v-row>

            </v-card-text>

        </v-card>







        
    </div>

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
    :dialogVisible="dialogLoaderVisible"
    :text="dialogLoaderText"
    transition="scroll-y-transition"
    ></DialogLoader>
</div>
</template>



<script>
import axios from "axios";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";

import validations from "@/core/untils/validations.js";

import {OBTENER_ITEMS_CATALOGO} from "@/core/services/store/catalogos/catalogointerno.module";
import { OBTENER_MONTO_SUSCRITO_CNSCOO, REGISTRAR_MONTO_SUSCRITO_CNSCOO, ACTUALIZAR_MONTO_SUSCRITO_CNSCOO, OBTENER_PROYECCIONES_DESEMBOLSOS_CNSCOO, REGISTRAR_PROYECCION_DESEMBOLSO_CNSCOO, ELIMINAR_PROYECCION_DESEMBOLSO_CNSCOO } from "@/core/services/store/proyectoscooperantes/datosfinancieroscnsc/datofinancierocnsc.module";

export default {
    name: "seccionDatosFinancierosCooperante",
    props: ['proyectoId', 'tipo'], //Tipo 1 segeplan (solo visualizar), tipo 2 externo (visualizar y editar)
    components: {
        SnackAlert,
        DialogLoader
    },
    data(){
        return{
            accionMontoSuscrito: 1,
            ddMonedasLoading: false,
            monedas: [],
            monedaMontoSeleccionada:{},
            monedaProyeccionSeleccionada: {},
            tipoCambioProyeccion: 0,
            montoMonedaOriginalProyeccion: 0,
            skeletonLoading: false,
            btnRegistroMontoSuscrito: false,
            validFormMontoSuscrito: false,
            btnRegistroProyeccion: false,
            validFormProyeccion: false,
            dialogLoaderVisible: false,
            dialogLoaderText: "Obteniendo información...",
            
            montoMonedaOriginalMonto: 0,
            tipoCambioMonto: 0,
            datosMontoSuscrito: {
                proyectoCNSCId: this.proyectoCNSCId,
                tipoCambio: "",
                montoMonedaOriginal: "",
                montoTotalQuetzales: "",
                monedasId: 0,
                estadosId: 1,   
                usuarioCreacion: "admin"

            },
            alertaTotalProyecciones: false,
            proyecciones: [],
            tableProyeccionesLoading:false,
            totalProyecciones: 0,
            datosProyeccion: {
                proyectoCNSCId: this.proyectoCNSCId,
                tipoCambio: "",
                montoMonedaOriginal: "",
                monedasId: 0,
                estadosId: 1,   
                usuarioCreacion: "admin"
            },
            montoSuscritoRegistrado: false,
            periodos:[],
            ddPeriodosLoading: false,
            totalMontoSuscrito:0,
            btnRegistroMontoSuscritoText: "Registrar monto suscrito",
            btnEliminarProyeccionDisabled: false,
            ...validations
        }
    },

    methods: {

        
        /**
        * Obtener el tipo de cambio de dolar actual
        */
        obtenerTipoCambio(){
            
            let xmls = `<soap12:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap12="http://www.w3.org/2003/05/soap-envelope">
            <soap12:Body>
                <TipoCambioDia xmlns="http://www.banguat.gob.gt/variables/ws/" />
            </soap12:Body>
            </soap12:Envelope>`;

        
            axios.post(
                "http://banguat.gob.gt/variables/ws/TipoCambio.asmx", 
                xmls,
                { headers: {
                    'Content-Type': 'application/soap+xml',
                    //'charset': 'utf-8',
                    'Access-Control-Allow-Origin': '*',
                    //'Access-Control-Allow-Credentials': 'true',
                    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                   // SOAPAction: "http://www.banguat.gob.gt/variables/ws/TipoCambioDia"
                 } }
                ).then(res=>{
                    console.log(res);
                }).catch(err=>{console.log(err)});
            
        },


        resetItems(){
            
            this.monedaMontoSeleccionada= {};
            this.montoMonedaOriginalMonto= 0;
            this.tipoCambioMonto= 0;

            this.skeletonLoading = true;
            this.totalMontoSuscrito=0;
            this.datosMontoSuscrito = {
                proyectoCNSCId: this.proyectoCNSCId,
                tipoCambio: "",
                montoMonedaOriginal: "",
                montoTotalQuetzales: "",
                monedasId: 0,
                estadosId: 1,   
                usuarioCreacion: "admin"

            };

            this.monedaProyeccionSeleccionada = {};
            this.montoMonedaOriginalProyeccion= 0;
            this.tipoCambioProyeccion= 0;

            this.datosProyeccion = {
                proyectoCNSCId: this.proyectoCNSCId,
                tipoCambio: "",
                montoMonedaOriginal: "",
                monedasId: 0,
                estadosId: 1,   
                usuarioCreacion: "admin"
            };
        },


        //Obtener monedas
        async obtenerMonedas(){

            this.monedas = [];

            

            this.ddMonedasLoading=true;
            
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Moneda/all/1'})
            .then(res => {
                if (res.status === 200) {
                    this.monedas = res.data;

                }
                this.ddMonedasLoading=false;
            })
            .catch(() => {
                this.monedas = [];
                this.ddMonedasLoading=false;
            });

        },

        //Obtener tipos de datos financieros
        async obtenerPeriodos(){

            this.periodos = [];

            this.ddPeriodosLoading=true;
            
            await this.$store
            .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Periodos/all/1'})
            .then(res => {
                if (res.status === 200) {
                    this.periodos = res.data;

                }
                this.ddPeriodosLoading=false;
            })
            .catch(() => {
                this.periodos = [];
                this.ddPeriodosLoading=false;
            });

        },


        //Obtener monto suscrito
        async obtenerMontoSuscrito(proyectoId){
            this.skeletonLoading = true;

            this.datosMontoSuscrito = {};
            await this.$store
            .dispatch(OBTENER_MONTO_SUSCRITO_CNSCOO, {id: proyectoId})
            .then(res => {
                if (res.status === 200) {
                   // console.log(res)
                    this.datosMontoSuscrito = res.data[0];
                    //this.monedaMontoSeleccionada.id = this.datosMontoSuscrito.monedasId;

                    //this.monedaMontoSeleccionada = this.monedas.find(item => item.id ===this.datosMontoSuscrito.monedasId);
                    
                    this.montoSuscritoRegistrado=true;
                    this.montoMonedaOriginalMonto = this.datosMontoSuscrito.montoMonedaOriginal;
                    this.tipoCambioMonto = this.datosMontoSuscrito.tipoCambio;
                    //this.datosProyeccion.montoTotalQuetzales = this.datosMontoSuscrito.montoTotalQuetzales;
                    this.btnRegistroMontoSuscritoText = "Actualizar monto suscrito";
                    this.totalMontoSuscrito = this.datosMontoSuscrito.montoTotalQuetzales;
                    this.accionMontoSuscrito = 2;
                   // this.totalProyecciones = this.proyecciones.reduce((a,b) => a + (b['montoTotalQuetzales'] || 0), 0);

                } else {
                    this.montoSuscritoRegistrado = false;
                    this.btnRegistroMontoSuscritoText = "Registrar monto suscrito";
                }
                this.skeletonLoading = false;
            })
            .catch(() => {
                this.datosMontoSuscrito = {};
                //this.tableProyeccionesLoading = false;
                this.skeletonLoading = false;
            });
        },


        //Obtener las proyecciones del pronecto cnsc cooperante
        async obtenerProyecciones(proyectoId){
            this.tableProyeccionesLoading = true;

            this.proyecciones = [];
            await this.$store
            .dispatch(OBTENER_PROYECCIONES_DESEMBOLSOS_CNSCOO, {id: proyectoId})
            .then(res => {
                if (res.status === 200) {
                    this.proyecciones = res.data;
                    this.totalProyecciones = this.proyecciones.reduce((a,b) => a + (b['montoTotalQuetzales'] || 0), 0);

                    if(parseFloat(this.totalProyecciones) > parseFloat(this.totalMontoSuscrito) || parseFloat(this.totalProyecciones) < parseFloat(this.totalMontoSuscrito)){
                        this.alertaTotalProyecciones= true;
                    } else{
                        this.alertaTotalProyecciones=false;
                    }

                }
                this.tableProyeccionesLoading = false;
                this.skeletonLoading = false;
            })
            .catch(() => {
                this.proyecciones = [];
                this.tableProyeccionesLoading = false;
                this.skeletonLoading = false;
            });

           
            
        },



        //Registrar/Actualizar monto suscrito
        async registrarMontoSuscrito(){
            this.btnRegistroMontoSuscrito=true;
            let endpoint = REGISTRAR_MONTO_SUSCRITO_CNSCOO;

            this.datosMontoSuscrito.tipoCambio = parseFloat(this.tipoCambioMonto);
            this.datosMontoSuscrito.montoMonedaOriginal = parseFloat(this.montoMonedaOriginalMonto);
            //this.datosMontoSuscrito.monedasId = this.monedaMontoSeleccionada.id;
            this.datosMontoSuscrito.estadosId = 1;
            this.datosMontoSuscrito.usuarioCreacion ='admin';
            this.datosMontoSuscrito.proyectoCNSCId = this.proyectoId;

            
            if(this.accionMontoSuscrito === 2){
                endpoint = ACTUALIZAR_MONTO_SUSCRITO_CNSCOO; 
            }


            await this.$store
            .dispatch(endpoint, {datos: this.datosMontoSuscrito})
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.accionMontoSuscrito = 2;
                    this.btnRegistroMontoSuscritoText = "Actualizar monto suscrito";
                    //this.obtenerCoberturaProyecto(this.id);             
                    //this.resetItems(2);
                    this.montoSuscritoRegistrado=true;
                    this.totalMontoSuscrito = this.datosMontoSuscrito.montoTotalQuetzales;
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnRegistroMontoSuscrito=false;
            })
            .catch(error => {
                
                 this.btnRegistroMontoSuscrito=false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        },



        //Registrar proyeccion desembolso
        async registrarProyeccion(){
            this.btnRegistroProyeccion=true;

            this.datosProyeccion.tipoCambio = parseFloat(this.tipoCambioProyeccion);
            this.datosProyeccion.montoMonedaOriginal = parseFloat(this.montoMonedaOriginalProyeccion); //
            this.datosProyeccion.monedasId = this.monedaProyeccionSeleccionada.id;
            this.datosProyeccion.estadosId = 1;
            this.datosProyeccion.usuarioCreacion ='admin';
            this.datosProyeccion.proyectoCNSCId = this.proyectoId;
           
            await this.$store
            .dispatch(REGISTRAR_PROYECCION_DESEMBOLSO_CNSCOO, {datos: this.datosProyeccion})
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.monedaProyeccionSeleccionada={};
                    this.montoMonedaOriginalProyeccion=0;
                    this.tipoCambioProyeccion=0;

                    this.datosProyeccion = {
                        proyectoCNSCId: this.proyectoId,
                        tipoCambio: "",
                        montoMonedaOriginal: "",
                        monedasId: 0,
                        estadosId: 1,   
                        usuarioCreacion: "admin"
                    };
                    this.obtenerProyecciones(this.proyectoId);
                    //this.obtenerCoberturaProyecto(this.id);             
                    //this.resetItems(2);
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnRegistroProyeccion=false;
            })
            .catch(error => {
                
                 this.btnRegistroProyeccion=false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        },


        //Eliminar proyeccion
        async eliminarProyeccion(proyeccionId){
            //console.log(proyeccionId)
            this.btnEliminarProyeccionDisabled=true;

            await this.$store
            .dispatch(ELIMINAR_PROYECCION_DESEMBOLSO_CNSCOO, proyeccionId)
            .then(res => {
                
                //this.dialogLoaderVisible = false;
                if (res.status === 200) {
                    this.$refs.snackalert.SnackbarShow("success", "Mensaje", res.message);
                    this.obtenerProyecciones(this.proyectoId);             
                   //this.resetItemsDesembolsos();
                } else {
                    this.$refs.snackalert.SnackbarShow("warning", "Alerta", res.message);
                }
                this.btnEliminarProyeccionDisabled=false;
            })
            .catch(error => {
                
                 this.btnEliminarProyeccionDisabled=false;
                 this.$refs.snackalert.SnackbarShow("warning", "Alerta", `Ha ocurrido un error durante el registro. ${error}`
                );
            });
        }



    },

    created(){
       // this.obtenerTipoCambio();
        //this.skeletonLoading=true;
        this.obtenerMonedas();
        this.obtenerPeriodos();
        this.resetItems();
        this.obtenerMontoSuscrito(this.proyectoId);
        this.obtenerProyecciones(this.proyectoId);

    },

     watch:{

        montoMonedaOriginalMonto : function(){
            this.datosMontoSuscrito.montoTotalQuetzales = (this.montoMonedaOriginalMonto * this.tipoCambioMonto);
        },

        tipoCambioMonto : function() {
            this.datosMontoSuscrito.montoTotalQuetzales = (this.montoMonedaOriginalMonto * this.tipoCambioMonto);
        },


        montoMonedaOriginalProyeccion : function(){
            this.datosProyeccion.montoTotalQuetzales = (this.montoMonedaOriginalProyeccion * this.tipoCambioProyeccion);
        },

        tipoCambioProyeccion : function() {
            this.datosProyeccion.montoTotalQuetzales = (this.montoMonedaOriginalProyeccion * this.tipoCambioProyeccion);
        },

        accionMontoSuscrito : function(){
            this.accionMontoSuscrito === 1 ? this.btnRegistroMontoSuscritoText = "Registrar monto suscrito" : this.btnRegistroMontoSuscritoText = "Actualizar monto suscrito";
        },

        totalProyecciones : function (){
            (parseFloat(this.totalProyecciones) > parseFloat(this.totalMontoSuscrito)) || (parseFloat(this.totalProyecciones) < parseFloat(this.totalMontoSuscrito)) ? this.alertaTotalProyecciones= true : this.alertaTotalProyecciones= false;
        },

        totalMontoSuscrito  : function (){
            (parseFloat(this.totalProyecciones) > parseFloat(this.totalMontoSuscrito)) || (parseFloat(this.totalProyecciones) < parseFloat(this.totalMontoSuscrito)) ? this.alertaTotalProyecciones= true : this.alertaTotalProyecciones= false;
        },


        


    },
    
    computed: {
        headersProyecciones() {
            return [
                {
                    text: "Año",
                    align: "start",
                    sortable: true,
                    value: "periodo"
                },
                {
                    text: "Moneda",
                    align: "start",
                    sortable: true,
                    value: "nombreMoneda"
                },
                {
                    text: "Monto Original",
                    align: "start",
                    sortable: true,
                    value: "nombreMoneda"
                },
                {
                    text: "Tipo de cambio",
                    align: "start",
                    sortable: true,
                    value: "nombreMoneda"
                },
                {
                    text: "Monto total en quetzales",
                    align: "start",
                    sortable: true,
                    value: "montoTotalQuetzales"
                },
                {
                    text: "Acción",
                    align: "start",
                    sortable: true,
                    value: ""
                },


            ]
        }

    }

    
}

</script>